<template>
    <div>
        <PromptControl @ok="register"
            @closed="closes"
            v-bind:show="show_prompt"
            title="Registration"
            message="Are you sure you want to register?"  />
        <MessageBoxControl @closed="closed" 
            v-bind:title="title"
            v-bind:show="show" 
            v-bind:message="message" 
            v-bind:theme="theme" />
        <NavbarSection title="Election Registration" />
        <ContentWrapper class="mt-4">
            <div class="bg-white shadow rounded leading-5 overflow-hidden">
                <div>
                    <img v-bind:src="image" class="w-full m-0 p-0 border-0" />
                </div>
                <div class="p-4 pb-0 leading-normal text-sm">
                    The annual election will start in March 27, 2023 for online voting and March 31, 2023 for on-site voting at PAFCPIC Main Office in Fort Bonifacio. You can
                    now register but you can only vote during the election period.
                </div>
                <div class="p-4">
                    <ButtonControl v-on:click="open">Register</ButtonControl>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import mixin from './../../mixin.js';
import NavbarSection from './../../components/sections/NavbarSection.vue';
import PromptControl from './../../components/controls/PromptControl.vue';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
        MessageBoxControl,
        NavbarSection,
        PromptControl,
    },
    data() {
        return {
            show: false,
            message: '',
            image: '',
            theme: 'error',
            title: 'Error',
            show_prompt: false,
            success: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.image = this.getMediaPath('/misc/pafcpic-election-2022.jpg');

        let state = this.$store.state.election;

        if(state.mock_started && !state.mock_ended && !state.registered) {
            this.$router.push('/dashboard');
        }
        else if(state.registered) {
            this.$router.push('/election/policy');
        }
        else {
            this.$emit('hideloader');
        }
    },
    methods: {
        register() {
            this.$emit('showloader');
            let that = this;

            Election.register(this.$store.state.uuid).then(function(response) {
                that.$store.state.election.registered = true;
                that.title = 'Registered';
                that.theme = 'success';
                that.message = response.data.data.message;
                that.show = true;
                that.success = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.theme = 'error';
                that.title = 'Error';
                that.message = error.response.data.message;
                that.show = true;
                that.success = false;
                that.$emit('hideloader');
            });
        },
        closed() {
            this.show = false;

            let store = this.$store.state.election;

            if(this.success && store.voting_started && store.registered && store.migs) {
                this.$router.push('/election/policy');
            }
            else {
                this.$router.push('/dashboard');
            }
        },
        open() {
            this.show_prompt = true;
        },
        closes() {
            this.show_prompt = false;
        }
    }
}
</script>