<template>
    <div class="bg-grey-light p-1 rounded mb-1 overflow-hidden flex">
        <div v-bind:style="[ getSource() ]" class="w-menu bg-cover bg-no-repeat bg-center rounded-full"></div>
        <div v-text="name" class="p-2 text-xs text-grey-darkest"></div>
    </div>
</template>

<script>
export default {
    name: 'v-vote-card-item',
    props: {
        id: {
            type: String,
        },
        index: {
            type: Number,
        },
        candidate: {
            type: String,
        }
    },
    data() {
        return {
            name: '',
        }
    },
    mounted() {
        let data = this.$store.state.candidates[this.id],
            that = this;

        data.forEach(function(item) {
            if(item.key == that.candidate) {
                that.name = item.name;
            }
        });
    },
    methods: {
        getSource() {
            return "background-image: url('" + process.env.VUE_APP_MEDIA_URL + "images/candidates/g/" + this.candidate + ".jpg')";
        },
    }
}
</script>