<template>
    <div class="h-screen w-full flex flex-col">
        <div v-on:click="$router.push('/vote/otp')" class="flex-1 bg-primary w-full text-white">
            <div class="font-bold flex h-full items-center justify-center"  style="font-size: 60px">OTP</div>
        </div>
        <div v-on:click="openQr" class="flex-1 bg-secondary w-full text-primary">
            <div class="font-bold flex h-full items-center justify-center" style="font-size: 60px">QR CODE</div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$emit('hidenavbar');
    },
    methods: {
        openQr() {
            if(window.android && window.android.openQr) {
                window.android.openQr();
            }
        },
    }
}
</script>

<style scoped>

</style>