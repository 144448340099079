<template>
    <div class="p-4">
        <div v-text="message" class="bg-red-light p-4 rounded-t text-md text-white border-red border-1"></div>
        <div class="bg-white p-4 rounded-b shadow">
            <ButtonControl v-on:click="back">
                Back
            </ButtonControl>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import Election from './../../services/Election.js';
import Member from './../../services/Member.js';

export default {
    components: {
        ButtonControl,
    },
    data() {
        return {
            qr: '',
            message: '',
        }
    },
    mounted() {
        this.$emit('showloader');
        this.qr = this.$route.query.qr;
        this.$emit('shownavbar', {
            type: 'normal',
            title: 'Authentication Failed!',
            show: true,
            back: false,
        });

        if(this.qr.length == 32) {
            this.validateQr(this.qr);
        }
    },
    methods: {
        back() {
            if(window.android && window.android.back) {
                window.android.back();
            }
        },
        validateQr(qr) {
            let that = this;
            
            Member.authenticate(qr).then(function(response) {
                let data = response.data.data;
                
                that.$store.state.uuid = data.uuid;
                that.$store.state.username = data.username;

                that.start(that.$store.state.uuid);
            })
            .catch(function(e) {
                that.error(e);
            });
        },
        start(uuid) {
            let that = this;

            Election.start(uuid).then(function() {
                that.$store.state.election.registered = true;
                that.$router.push('/election/policy');
                that.$emit('hideloader');
            }).catch(function(e) {
                that.error(e);
            });
        },
        error(e) {
            let message = e.response.data.message;

            this.message = message;
            this.$store.state.uuid = null;
            this.$store.state.username = null;
            this.$emit('hideloader');
        },
    }
}
</script>