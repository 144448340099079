<template>
    <ContentWrapper class="mt-4">
        <div class="bg-white shadow rounded p-4 mt-4 mb-6">
            <div class="leading-normal text-md">You have successfully voted for this year's election. <br /><br />Salamat <b>Ka-May-Ari</b>!</div>
            <div class="mt-6">
                <ButtonControl v-on:click="exit">
                    Exit
                </ButtonControl>
            </div>
        </div>
    </ContentWrapper>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';

export default {
    components: {
        ButtonControl,
        ContentWrapper,
    },
    mounted() {
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            type: 'normal',
            title: 'Congratulations!',
            show: true,
            back: false,
        });
    },
    methods: {
        exit() {
            if(window.android && window.android.back)
            {
                window.android.back();
            }
        }
    }
}
</script>