<template>
    <div>
        <VoteSection @preload="preload"
            @loaded="loaded"
            :max="1"
            id="elecom_ci"
            category="elecom"
            type="ci" 
            title="Election Committee"
            subtitle="Civilian"
            href="/election/vote/audit"
            color="bg-theme-elecom"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';

export default {
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('nofooter');
        this.$emit('checkauth');
        this.$emit('electioncheck');
    },
    methods: {
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>