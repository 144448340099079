<template>
    <div class="flex">
        <div class="w-menu mr-2">
            <img v-on:click="toggle(1)" v-bind:src="getIcon(star1)" />
        </div>
        <div class="w-menu mr-2">
            <img v-on:click="toggle(2)" v-bind:src="getIcon(star2)" />
        </div>
        <div class="w-menu mr-2">
            <img v-on:click="toggle(3)" v-bind:src="getIcon(star3)" />
        </div>
        <div class="w-menu mr-2">
            <img v-on:click="toggle(4)" v-bind:src="getIcon(star4)" />
        </div>
        <div class="w-menu mr-2">
            <img v-on:click="toggle(5)" v-bind:src="getIcon(star5)" />
        </div>
        <div v-text="desc" class="flex-grow text-sm font-bold papsi-rating-lh"></div>
    </div>
</template>

<script>
import Support from './../../services/Support.js';
import mixin from './../../mixin.js';

export default {
    name: 'v-rating',
    mixins: [
        mixin,
    ],
    props: {
        type: {
            type: Number,
        },
        submit: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            uuid: null,
            value: -1,
            star1: false,
            star2: false,
            star3: false,
            star4: false,
            star5: false,
            desc: '',
            descriptions: [],
        }
    },
    mounted() {
        this.uuid = this.$store.state.uuid;
        this.descriptions.push(this.lang('very.bad'));
        this.descriptions.push(this.lang('bad'));
        this.descriptions.push(this.lang('moderate'));
        this.descriptions.push(this.lang('good'));
        this.descriptions.push(this.lang('very.good'));
    },
    methods: {
        getIcon(status) {
            return require(status ? "@/assets/icons/papsi_star_on.svg" : "@/assets/icons/papsi_star_off.svg");
        },
        toggle(n) {
            this.desc = this.descriptions[n - 1];
            this.value = n;

            if(n == 1) {
                this.star1 = true;
                this.star2 = false;
                this.star3 = false;
                this.star4 = false;
                this.star5 = false;
            }
            else if(n == 2) {
                this.star1 = true;
                this.star2 = true;
                this.star3 = false;
                this.star4 = false;
                this.star5 = false;
            }
            else if(n == 3) {
                this.star1 = true;
                this.star2 = true;
                this.star3 = true;
                this.star4 = false;
                this.star5 = false;
            }
            else if(n == 4) {
                this.star1 = true;
                this.star2 = true;
                this.star3 = true;
                this.star4 = true;
                this.star5 = false;
            }
            else if(n == 5) {
                this.star1 = true;
                this.star2 = true;
                this.star3 = true;
                this.star4 = true;
                this.star5 = true;
            }

            this.$emit('toggled', n);
        },
        submitRate(n) {
            let that = this;
            Support.add(this.uuid, this.type, n).then(function() {
                that.$emit('submitted');
            }).catch(function(error) {
                that.$emit('submitted');
                that.$terminate(error.response.status);
            });
        }
    },
    watch: {
        submit(n) {
            if(n) {
                this.submitRate(this.value);
            }
        }
    }
}
</script>

<style scoped>
.papsi-rating-lh {
    line-height: 32px;
}
</style>