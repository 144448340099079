<template>
    <ContentWrapperControl>
        <MessageBoxControl @closed="closes"
            v-bind:show="display"
            v-bind:message="message"
            v-bind:title="title" />
        <div class="bg-white shadow rounded p-4 mt-4">
            <div class="pb-6 leading-normal italic text-md border-b">Please enter the one time password we have sent to your email address or mobile number.</div>
            <div class="mb-4 mt-6">
                <TextControl @typing="typing"
                    v-bind:value="otp"
                    :maxlength="6"
                    placeholder="Enter OTP"
                    type="text"
                    name="otp"
                    uppercase />
            </div>
            <div>
                <ButtonControl v-on:click="proceed" v-bind:disabled="disabled">
                    {{ lang('proceed') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        MessageBoxControl,
        TextControl,
    },
    data() {
        return {
            otp: '',
            disabled: true,
            display: false,
            message: '',
            title: 'ERROR',
        }
    },
    mounted() {
        this.$emit('shownavbar', {
            type: 'normal',
            title: 'One Time Password',
            show: true,
            back: true,
        });
    },
    methods: {
        typing(obj) {
            this[obj.name] = obj.value;
            this.disabled = this.otp.length == 0;
        },
        proceed() {
            let that = this;
        
            that.$emit('showloader');
            Election.otp(this.otp).then(function(response) {
                let uuid = response.data.data.uuid;

                that.$store.state.uuid = uuid;
                that.$store.state.election.registered = true;
                that.start(uuid);
            })
            .catch(function(e) {
                that.otp = '';
                that.message = e.response.data.message;
                that.$emit('hideloader');
                that.display = true;
            });
        },
        start(uuid) {
            let that = this;

            Election.start(uuid).then(function() {
                that.$router.push('/election/policy');
                that.$emit('hideloader');
            })
            .catch(function(e) {
                that.otp = '';
                that.message = e.response.data.message;
                that.$emit('hideloader');
                that.display = true;
            });
        },
        closes() {
            this.display = false;
        }
    }
}
</script>