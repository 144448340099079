<template>
    <div>
        <VoteSection @preload="preload"
            @loaded="loaded"
            :max="2"
            id="audit"
            category="audit"
            title="Audit & Supervisory Committee"
            href="/election/vote/summary"
            color="bg-theme-adcom"
            icon="papsi_selected_audit.svg"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';

export default {
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('nofooter');
        this.$emit('checkauth');
        this.$emit('electioncheck');
    },
    methods: {
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>