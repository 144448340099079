<template>
    <div>
        <VoteSection @preload="preload"
            @loaded="loaded"
            :max="8"
            id="bod"
            category="bod" 
            title="Board of Directors"
            href="/election/vote/elecom/officer"
            color="bg-theme-bod"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';

export default {
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            type: 'primary',
            title: 'Board of Directors',
            show: false,
            back: true,
        });
    },
    methods: {
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>